import './src/styles/global.css';
import './src/styles/reset.css';

import * as Sentry from '@sentry/gatsby';

declare const process: {
  env: {
    [key: string]: string | undefined;
  };
};

export const onClientEntry = () => {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error', 'assert'],
      }),
    ],

    tracesSampleRate: 1.0,
  });
};
